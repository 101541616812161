<template>
  <div class="marker-selection">
    <marker-select-dwm
      v-if="block"
      :marker="marker"
      :info-as-popup="infoAsPopup"
      :show-sum="showSum"
      :show-toggle-icon="showToggleIcon"
      :dwm-plan="markerDwmPlan"
    />
    <marker-request-offer
      v-else
      :marker="marker"
    />
  </div>
</template>

<script>
import MarkerSelectDwm from './components/dwm';
import MarkerRequestOffer from './components/offer';

export default {
  components: {
    MarkerSelectDwm,
    MarkerRequestOffer,
  },
  props: {
    marker: {
      type: Object,
      default: () => {},
    },
    infoAsPopup: {
      type: Boolean,
      default: undefined,
    },
    showSum: {
      type: Boolean,
      default: false,
    },
    showToggleIcon: {
      type: Boolean,
      default: false,
    },
    dwmPlan: {
      type: Array,
      default: () => [],
    },
    includeMarkerSelection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      markerSelectedDwm: [],
    };
  },
  computed: {
    block() {
      return this.marker.block;
    },
    markerDwmPlan() {
      return this.includeMarkerSelection
        ? this.$store.getters.sortDwmList([
          ...this.dwmPlan,
          ...this.markerSelectedDwm.filter((dwm) => !this.dwmPlan.includes(dwm)),
        ]) : this.dwmPlan;
    },
  },
  watch: {
    marker: {
      handler: 'fillMarkerSelectedDwm',
      immediate: true,
    },
  },
  methods: {
    fillMarkerSelectedDwm() {
      this.markerSelectedDwm = [
        ...(this.$store.getters.cartData[this.marker.id]?.selectedDwm || []),
      ];
    },
  },
};
</script>

<style lang="scss">
.marker-selection {
  width: 185px;
}
</style>
