<template>
  <label
    class="app-input"
    :class="{'app-input--error': error}"
  >
    <span
      v-if="label"
      class="app-input__label"
    >{{ label }}</span>

    <textarea
      v-if="type === 'textarea'"
      v-model="localValue"
      class="app-input__input app-input__input--textarea"
      :placeholder="placeholder"
    />
    <input
      v-else
      v-model="localValue"
      class="app-input__input"
      :type="type"
      :placeholder="placeholder"
    >
    <button
      v-if="resetButton"
      class="app-input__input-reset-btn"
      @click="clearValue"
    >
      &#10006;
    </button>
    <span class="app-input__error">
      {{ error }}
    </span>
  </label>
</template>

<script>

function debounce(callback, wait) {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
}

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    resetButton: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    debounce: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      updateValueDebounce: debounce(this.updateValue, this.debounce),
    };
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(value) {
        this.updateValueDebounce(value);
      },
    },
  },
  methods: {
    clearValue() {
      this.updateValue('');
    },
    updateValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss">
.app-input {
  $root: &;

  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 18px;

  &__label {
    margin-bottom: 10px;
  }

  &__input {
    @include input;
    margin-bottom: 20px;

    #{$root}--error & {
      @include input-error;
    }

    &-reset-btn {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 25px;
      right: 5px;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  &__error {
    position: absolute;
    bottom: 6px;

    font-size: 12px;
    color: $error-color;
  }
}

</style>
