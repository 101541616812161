<template>
  <div
    class="marker-request-offer"
    :class="{ 'marker-request-offer--active' : isChecked }"
  >
    <label class="marker-request-offer__label">
      <input
        v-model="isChecked"
        type="checkbox"
        class="marker-request-offer__checkbox"
      >
      <span class="marker-request-offer__control" />
      <span>Ich möchte ein unverbindliches Angebot zum Zeitraum:</span>
    </label>

    <app-select-daterange v-model="selectedDateRange" />
  </div>
</template>

<script>
import AppSelectDaterange from '@/components/app-select-daterange';

// daterange here is a array ['DD.MM.YYYY', 'DD.MM.YYYY']
export default {
  components: {
    AppSelectDaterange,
  },
  props: {
    marker: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localDateRange: null,
    };
  },
  computed: {
    selectedDateRange: {
      get() {
        const dateRange = (this.markerDateRange || this.localDateRange || this.defaultDateRange);
        return dateRange;
      },
      set(value) { this.setDateRange(value); },
    },
    isChecked: {
      get() { return !!this.markerDateRange; },
      set(value) {
        if (value) {
          this.setDateRange(this.selectedDateRange);
        } else {
          this.setDateRange(null);
        }
      },
    },
    markerDateRange() {
      return this.$store.getters.cartData[this.marker.id]?.dateRange;
    },
    defaultDateRange() {
      return [
        this.$store.getters.firstAllowedDate,
        this.$store.getters.lastAllowedDate,
      ].map((m) => m.format('DD.MM.YYYY'));
    },
  },
  methods: {
    setDateRange(dateRange) {
      const { marker } = this;
      if (!Array.isArray(dateRange)) {
        this.$store.dispatch('deleteMarkerCart', { marker });
      } else if (dateRange.every((date) => date === null)) {
        this.$store.dispatch('deleteMarkerCart', { marker });
        this.localDateRange = null;
      } else {
        this.$store.dispatch('updateMarkerCart', { marker, dateRange });
        this.localDateRange = [...dateRange];
      }
    },
  },
};
</script>

<style lang="scss">
.marker-request-offer {
  $root: &;

  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  &__label {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 5px;
    cursor: pointer;

    input {
      display: none;
    }
  }

  &__control {
    &:hover,
    #{$root}--active & {
      @include icon(check2);
    }

    &::before {
      content: "";
      color: $result-offer-color;
      background-color: $result-offer-bg-color;
      border: $result-offer-border;

      box-sizing: border-box;
      font-size: 18px;
      width: 25px;
      height: 25px;

      margin-right: 6px;
      display: flex;
      justify-content: center;
      align-items: center;

      #{$root}--active & {
        color: $result-offer-color-active;
        background-color: $result-offer-bg-color-active;
        border-color: $result-offer-border-color-active;
      }
    }
  }

  .app-select-daterange {
    &__input {
      height: 28px;
      font-size: 12px;
      color: $result-offer-color;
      background-color: $result-offer-bg-color;
      border: $result-offer-border;
    }
  }

  &--active {
    .app-select-daterange {
      &__input {
        color: $result-offer-color-active;
        background-color: $result-offer-bg-color-active;
        border-color: $result-offer-border-color-active;
      }
    }
  }

}
</style>
