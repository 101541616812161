const isTouchDevice = () => {
  if (
    'ontouchstart' in window
    // eslint-disable-next-line no-undef
    || (window.DocumentTouch && document instanceof DocumentTouch)
  ) {
    return true;
  }

  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

  const mq = (query) => window.matchMedia(query).matches;

  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');

  return mq(query);
};

export default isTouchDevice;
