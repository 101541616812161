<template>
  <div
    v-if="markerDwmPlan.length"
    class="marker-select-dwm"
    :class="{'marker-select-dwm--info-inline': !infoAsPopup}"
  >
    <div
      v-for="(yearMarkerDwmPlan, year) in markerDwmPlanByYears"
      :key="year"
      class="marker-select-dwm__year"
    >
      <div
        v-if="actualYears.length > 1"
        class="marker-select-dwm__title"
      >
        {{ year }}:
      </div>
      <div class="marker-select-dwm__content">
        <div
          v-for="(dwm, index) in yearMarkerDwmPlan"
          :key="index"
          class="marker-select-dwm__dwm"
        >
          <app-dwm-checkbox
            v-model="markerSelectedDwm"
            :value="dwm"
            :label="dwm.number"
            :info="getInfo(dwm)"
            :disabled="!isChecked(dwm) && isLocked(dwm)"
            :locked="isLocked(dwm)"
            :info-as-popup="infoAsPopup"
          />
          <div
            v-if="showSum"
            class="marker-select-dwm__dwm-sum"
          >
            {{ isChecked(dwm) ? sum(dwm) : 0 | euro }}
          </div>
          <button
            v-if="showToggleIcon"
            class="marker-select-dwm__dwm-toggle"
            :class="isChecked(dwm) ? 'btn-delete' : 'btn-add'"
            @click="toggle(dwm)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isTouchDevice from '@/helpers/isTouchDevice';

import AppDwmCheckbox from '@/components/app-dwm-checkbox';

export default {
  components: {
    AppDwmCheckbox,
  },
  props: {
    marker: {
      type: Object,
      default: () => {},
    },
    infoAsPopup: {
      type: Boolean,
      default: !isTouchDevice(),
    },
    dwmPlan: {
      type: Array,
      default: () => [],
    },
    showSum: {
      type: Boolean,
      default: false,
    },
    showToggleIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    markerSelectedDwm: {
      get() {
        return this.$store.getters.cartData[this.marker.id]?.selectedDwm || [];
      },
      set(selectedDwm) {
        const { marker } = this;
        this.$store.dispatch('updateMarkerCart', { marker, selectedDwm });
      },
    },
    actualYears() {
      return this.$store.getters.actualYears;
    },
    markerDwmPlan() {
      return this.$store.getters.filterDwmPlanByBlocks(this.dwmPlan, this.marker.block);
    },
    markerDwmPlanByYears() {
      return Object.fromEntries(
        this.actualYears
          .map((year) => [year, this.$store.getters.filterDwmPlanByYears(this.markerDwmPlan, year)])
          .filter(([, dwmPlan]) => dwmPlan.length),
      );
    },
  },
  methods: {
    getInfo(dwm) {
      return this.$store.getters.getDwmPeriodInfo(dwm);
    },
    isChecked(dwm) {
      return this.markerSelectedDwm.includes(dwm);
    },
    isLocked(dwm) {
      const { marker } = this;
      return this.$store.getters.isDwmLockedForMarker({ dwm, marker });
    },
    sum(dwm) {
      const { marker } = this;
      return this.$store.getters.getMarkerDwmCartSum({ dwm, marker });
    },
    toggle(dwm) {
      if (this.isChecked(dwm)) {
        this.markerSelectedDwm = this.markerSelectedDwm.filter((el) => el !== dwm);
      } else {
        this.markerSelectedDwm = [...this.markerSelectedDwm, dwm];
      }
    },
  },
};
</script>

<style lang="scss">
.marker-select-dwm {
  $root: &;

  &__year {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;

    #{$root}--info-inline & {
      flex-direction: column;
      flex-wrap: nowrap;

      max-height: 100%;
      overflow-y: auto;
    }
  }

  &__dwm {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    align-items: center;

    .app-dwm-checkbox {
      margin: 2px;
    }

    &-sum {
      margin-left: auto;
      flex: 0 0 70px;
      max-width: 70px;
      align-items: left;
    }
  }
}
</style>
