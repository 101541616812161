<template>
  <img
    v-if="media.image"
    class="app-media-image"
    :src="media[imageType] || media.image"
  >
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    imageType: {
      type: String,
      default: 'image',
    },
  },
  computed: {
    media() {
      return this.$store.getters.mediaByTypes[this.type];
    },
  },
};
</script>

<style lang="scss">
</style>
